const endpoints = ({ axios, mapToQueryString, withoutInvalidValues }) => ({
    listActive: (id, query = {}, configuration = {}) => axios.get(`/consult?idExpert=${id}&active=true${mapToQueryString(query)}`, configuration),

    listFalse: (query = {}, configuration = {}) => axios.get(`/consult?active=false${mapToQueryString(query)}`, configuration),
    getConsult: (id, query = {}, configuration = {}) => axios.get(`/consult/find/${id}${mapToQueryString(query)}`, configuration),
    PutFinish: (id, body, configuration = {}) => axios.put(`/consult/${id}`, withoutInvalidValues(body), configuration),
    create: (body, configuration = {}) => axios.post('/consult/chat', withoutInvalidValues(body), configuration),
    upload: (body, configuration = {}) => axios.post('/consult/media', withoutInvalidValues(body), configuration),
    
    uploadPhoto: (body, configuration = {}) => axios.post('/consult/media', withoutInvalidValues(body), configuration),
    uploadVideo: (body, configuration = {}) => axios.post('/consult/media', withoutInvalidValues(body), configuration),
})

export default endpoints



