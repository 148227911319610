const endpoints = ({ axios, mapToQueryString, withoutInvalidValues }) => ({
  list: (query = {}, configuration = {}) => axios.get(`/post/admin${mapToQueryString(query)}`, configuration),
  listComent: (id, query = {}, configuration = {}) => axios.get(`/comment/list/${id}${mapToQueryString(query)}`, configuration),
  
  delete: (id, configuration = {}) => axios.delete(`/post/${id}`, configuration),

  create: (body, configuration = {}) => axios.post('/post', withoutInvalidValues(body), configuration),
  support: (body, configuration = {}) => axios.post('/support', withoutInvalidValues(body), configuration),
  createComent: (body, configuration = {}) => axios.post('/comment', withoutInvalidValues(body), configuration),
  update: (body, configuration = {}) => axios.put(`/post`, withoutInvalidValues(body), configuration),
})

export default endpoints