import React, { useEffect, useReducer } from "react"

// Reducers
import { AuthReducer } from './reducer'

// Hooks
import useRepository from '@hooks/useRepository' // aqui el llamado hooks nuestro

// Context
export const AuthStateContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const initialState = {
    user: null,
    loading: false,
    errorMessage: null,
    successMessage: null
  }

  const [authState, dispatch] = useReducer(AuthReducer, initialState)
  const post = useRepository(({ Auth }) => Auth.getUserInfo)

  useEffect(() => {
    (async function() {
      const token = window.localStorage.getItem('@TOKEN')

      if (token !== null) {
        const user = JSON.parse( window.localStorage.getItem('@USER') )
        dispatch({ type:'USER_INFO', payload: { user } })

        // try {
        //     const res = await post()
        //     if (res.status === 200) {
        //       dispatch({ type:'USER_INFO', payload: { user: res.result } })
        //     }
        // } catch (error) {
          
        // }
      }

    })()
  }, [])

  return (
    <AuthStateContext.Provider value={{ ...authState, dispatch }}>
      {children}
    </AuthStateContext.Provider>
  )
}
