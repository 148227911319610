/*
CONSULTAS -> LIST CONSULTS EXPERT -> 'GET /consults/expert/?idExpert=628d6939bf472a399badc793'
PAGOS -> PAYMENTS LIST EXPERT -> 'GET /payments/list/expert/?idExpert=628d6939bf472a399badc793'
PUBLICACIONES -> LISTAR POSTS USUARIOS/EXPERTOS -> 'GET /posts/list/user/?idUser=628d6939bf472a399badc793'
MEMBRESIAS -> LIST MEMBERS EXPERT -> 'GET /members/?idExpert=628d6939bf472a399badc793&status=true' status es opcional
*/

const endpoints = ({ axios, mapToQueryString, withoutInvalidValues }) => ({
    CONSULTS: (id, query = {}, configuration = {}) => axios.get(`/consults/expert/?idExpert=${id}${mapToQueryString(query)}`, configuration),
    PAYMENTS: (id, query = {}, configuration = {}) => axios.get(`/payments/list/expert/?idExpert=${id}${mapToQueryString(query)}`, configuration),
    PUBLICATIONS: (id, query = {}, configuration = {}) => axios.get(`/posts/list/user/?idUser=${id}${mapToQueryString(query)}`, configuration),
    MEMBERSHIPS: (id, query = {}, configuration = {}) => axios.get(`/members/?idExpert=${id}${mapToQueryString(query)}`, configuration),
})

export default endpoints