const endpoints = ({ axios, mapToQueryString, withoutInvalidValues }) => ({
  // index: (body, query = {}, configuration = {}) => axios.get(`/api/users${mapToQueryString(query)}`, body, configuration),

  show: (id, query = {}, configuration = {}) => axios.get(`/user/${id}${mapToQueryString(query)}`, configuration),
  showinfo: (_, configuration = {}) => axios.get(`/user/info`, configuration),

  getprojects: (_, configuration = {}) => axios.get(`/user/projects`, configuration),
  referrals: (_, configuration = {}) => axios.get(`/user/referrals`, configuration),
  stats: (_, configuration = {}) => axios.get(`/user/stats`, configuration),

  projects: (body, configuration = {}) => axios.post('/user/projects', withoutInvalidValues(body), configuration),
  donations: (body, configuration = {}) => axios.post('/user/donations', withoutInvalidValues(body), configuration),
  invitation: (body, configuration = {}) => axios.post('/user/invitation', withoutInvalidValues(body), configuration),
  contact: (body, configuration = {}) => axios.post('/user/contact', withoutInvalidValues(body), configuration),

  update: (body, configuration = {}) => axios.put(`/user`, withoutInvalidValues(body), configuration),

  // delete: (id, configuration = {}) => axios.delete(`/api/users/${id}`, configuration),
})

export default endpoints