const endpoints = ({ axios, mapToQueryString, withoutInvalidValues }) => ({
  ListPayments: (query = {}, configuration = {}) => axios.get(`/purchases${mapToQueryString(query)}`, configuration),

  ListPaymentsExpert: (id, query = {}, configuration = {}) => axios.get(`/payments/${id}${mapToQueryString(query)}`, configuration),

  FindForExpert: ( query = {}, configuration = {}) => axios.get(`/payments${mapToQueryString(query)}`, configuration),

  FinByld: (id, query = {}, configuration = {}) => axios.get(`/purchases/${id}${mapToQueryString(query)}`, configuration),

})

export default endpoints