const defaultMappedOptions = {
    prefix: '?',
    removingNull: true,
    removingUndefined: true,
    removingEmptyStrings: true,
  }
  
  /**
   * 
   * @param {object} json
   * @param {{prefix,removingNull,removingUndefined,removingEmptyStrings}} options
   * @returns {string}
   */
  export const mapToQueryString = (json, {
    prefix,
    removingNull,
    removingUndefined,
    removingEmptyStrings,
  } = defaultMappedOptions) => {
    let entries = Object.entries(json)
    
    if (removingEmptyStrings) {
      entries = entries.filter(pair => pair[1] !== '')
    }
  
    if (removingNull) {
      entries = entries.filter(pair => pair[1] !== null)
    }
  
    if (removingUndefined) {
      entries = entries.filter(pair => pair[1] !== undefined)
    }
  
    return entries.map(
      ([attribute, value], index) => `${index === 0 ? prefix : '&'}${attribute}=${value}`.replaceAll(/\s+/gm, '').trim() // removes all new lines, tabs and spaces
    )
    .join('')
  }
  
  /**
   * 
   * @param {object} json
   * @returns {FormData}
   */
  export const toFormData = json => {
    let data = new FormData()
    Object.entries(json)
        .forEach(pair => {
            if (Array.isArray(pair[1])) return pair[1].forEach(($1, key) => data.append(`${pair[0]}[${key}]`, $1))
  
            if (pair[1] === true) return data.append(pair[0], 1)
  
            if (pair[1] === false) return data.append(pair[0], 0)
  
            // if (Object.isObject(pair[1])) return toFormData(pair[1]) // EXPERIMENTAL
  
            data.append(pair[0], pair[1])
        })
    
    return data
  }
  
  /**
   * 
   * @param {object} json 
   * @returns {object}
   */
  export const withoutInvalidValues = (json, ...invalidValues) => {
  
    /**
     * {...json} creates a copy of the incoming json
     * in order to not modify the original reference
     */
    Object.entries({...json})
        .forEach(([attribute, value]) => {
  
            if (invalidValues.includes(value)) {
                delete json[attribute[0]]
            } else if (typeof value === 'object') {
  
                if (!Array.isArray(value)) {
                    withoutInvalidValues(value)
                } else {
                    if (!value.length) {
                        delete json[attribute]
                    }
                }
            }
        })
  
    return json
  }
  
  export default withoutInvalidValues