const endpoints = ({ axios, mapToQueryString, withoutInvalidValues }) => ({

    showCountrylist: ( query = {}, configuration = {}) => axios.get(`/countrylist${mapToQueryString(query)}`, configuration),
    countryList: ( query = {}, configuration = {}) => axios.get(`/countrylist${mapToQueryString(query)}`, configuration),
    listBanck: ( query = {}, configuration = {}) => axios.get(`/banck${mapToQueryString(query)}`, configuration),
    listCountry: ( query = {}, configuration = {}) => axios.get(`/country${mapToQueryString(query)}`, configuration),
    listConsultsExpense: ( query = {}, configuration = {}) => axios.get(`/expense${mapToQueryString(query)}`, configuration),
    listCommisionsCost: ( query = {}, configuration = {}) => axios.get(`/CommisionsCost'${mapToQueryString(query)}`, configuration),


    commissions: (body, configuration = {}) => axios.post('/commissions',  withoutInvalidValues(body), configuration),
    createBanck: (body, configuration = {}) => axios.post('/banck',  withoutInvalidValues(body), configuration),
    createConsultsExpense: (body, configuration = {}) => axios.post('/expense',  withoutInvalidValues(body), configuration),
    createCountry: (body, configuration = {}) => axios.post('/country',  withoutInvalidValues(body), configuration),


    updateCountry: (body, configuration = {}) => axios.put('/country',  withoutInvalidValues(body), configuration),
    updateConsultsExpense: (body, configuration = {}) => axios.put('/expense',  withoutInvalidValues(body), configuration),
    UpdateBanck: (body, configuration = {}) => axios.put('/banck',  withoutInvalidValues(body), configuration),
    getCommisionsCost: (body, configuration = {}) => axios.get('/CommisionsCost',  withoutInvalidValues(body), configuration),
    updateConsulsCost: (body, configuration = {}) => axios.put('/CommisionsCost',  withoutInvalidValues(body), configuration),



    deleteBanck: (id, configuration = {}) => axios.delete(`/banck/${id}`, configuration),
    deleteCountry: (id, configuration = {}) => axios.delete(`/country/${id}`, configuration),
    deleteConsultsExpense: (id, configuration = {}) => axios.delete(`/expense/${id}`, configuration),
  })
  
  export default endpoints