import axios from 'axios'
import { toast } from 'react-toastify';
import { API_URL } from "@src/constants"
const onRequest = (config) => {

  const token = localStorage.getItem('@TOKEN')

  if (token) {
    config.headers['Authorization'] = token
  }

  return config
}

/**
 * 
 * @param {AxiosResponse} response 
 * @returns {AxiosResponse}
 */
const onResponse = response => response

/**
 * 
 * @param {Error} error 
 * @returns {Promise<Error>}
 */
const onRequestError = error => Promise.reject(error)

/**
 * 
 * @param {Error} error 
 * @returns {Promise<Error>}
 */
const onResponseError = error => {
  try {
    if (error.response){
      toast.error( error.response.data.message_es , {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    } 
     
    if(error.response.status === 403){
      localStorage.removeItem('@TOKEN');
      localStorage.removeItem('@USER');
    }
    Promise.reject(error)
  } catch (error) {
    Promise.reject(error)
  }

}

const useConfiguredAxios = () => {
  const _axios = axios.create({
    baseURL: API_URL
  })

  _axios.interceptors.request.use(onRequest, onRequestError)
  _axios.interceptors.response.use(onResponse, onResponseError)

  return _axios
}

export default useConfiguredAxios