import { mapToQueryString, withoutInvalidValues, toFormData } from './utils';
import * as repository from './repository'

const endpoints = axios => Object.entries(repository)
.reduce((repository, [attribute, value]) => ({
  ...repository,
  [attribute]: value({
    axios,
    mapToQueryString,
    withoutInvalidValues,
    toFormData,
    getToken: () => localStorage.getItem('token')
})}), {})

export default endpoints