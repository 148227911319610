/*
ENDPOINTS PARA USUARIO
CONSULTAS -> LIST CONSULTS USER -> 'GET /consults/user/?idUser=628d19299891e55668ac043f'
PAGOS -> PAYMENTS LIST USER -> 'GET /payments/list/user/?idUser=6257212223d6cf13e13a4470'
PUBLICACIONES -> LISTAR POSTS USUARIOS/EXPERTOS -> 'GET /posts/list/user/?idUser=6257212223d6cf13e13a4470'
MEMBRESIAS -> LIST MEMBERSHIP USER -> 'GET /membership/?idUser=6276862af687482542dbb41b&status=true' status es opcional
*/

const endpoints = ({ axios, mapToQueryString, withoutInvalidValues }) => ({
    CONSULTS: (id, query = {}, configuration = {}) => axios.get(`/consults/user/?idUser=${id}${mapToQueryString(query)}`, configuration),
    PAYMENTS: (id, query = {}, configuration = {}) => axios.get(`/payments/list/user/?idUser=${id}${mapToQueryString(query)}`, configuration),
    PUBLICATIONS: (id, query = {}, configuration = {}) => axios.get(`/posts/list/user/?idUser=${id}${mapToQueryString(query)}`, configuration),
    MEMBERSHIPS: (id, query = {}, configuration = {}) => axios.get(`/membership/?idUser=${id}${mapToQueryString(query)}`, configuration),
})

export default endpoints