const endpoints = ({ axios, mapToQueryString, withoutInvalidValues }) => ({
    list: (query = {}, configuration = {}) => axios.get(`/notifications${mapToQueryString(query)}`, configuration),
    show: (id, query = {}, configuration = {}) => axios.get(`/notifications/${id}${mapToQueryString(query)}`, configuration),
    NotificationsList: ( query = {}, configuration = {}) => axios.get(`/notifications${mapToQueryString(query)}`, configuration),

    
    create: (body, configuration = {}) => axios.post('/notifications', withoutInvalidValues(body), configuration),
    createNotification: (body, configuration = {}) => axios.post('/notifications', withoutInvalidValues(body), configuration),

    deleteNotification: (id, configuration = {}) => axios.delete(`/notifications/${id}`, configuration),
    update: (body, configuration = {}) => axios.put(`/notifications`, withoutInvalidValues(body), configuration),
  })
  
  export default endpoints